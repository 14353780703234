import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import PageWrapper from "../components/PageWrapper";
import { Section, Title, Text, Box, List } from "../components/Core";

const TermsConditions = () => {
  return (
    <>
      <PageWrapper footerDark>
        <Section>
          <div className="pt-5"></div>
          <Container>
            <Row className="justify-content-center">
              <Col lg="9" xl="8">
                <Title variant="hero" className="text-center">
                  Terms & Conditions
                </Title>

                <Box mt={["40px", null, "50px"]}>
                  <p>
                    Please read the terms and conditions carefully before using HotSnail. By applying for services
						at HotSnail, you are accepting the terms and conditions of this contract.
					</p>

                  <p>Definitions</p>
                  <p>By “You”, we mean the person who submitted the application.</p>
                  <p>HotSnail is a business of Digital Blitz Pty Ltd ACN 111 323 445</p>
                  <p>Applicable rates are identified on HotSnail website under “Plans and Pricing”.</p>
                  <p>
                    HotSnail online mail management interface is located at www.HotSnail.com.au after a successful
						login.
					</p>

                  <p>
                    <b>General</b>
                  </p>
                  <p>
                    HotSnail is a mail processing service and online mail management tool. HotSnail will not
						knowingly accept, forward or be held responsible for any illegal, damaged or hazardous mail
						belonging to or being sent to you. HotSnail reserves the right to refuse acceptance of such
						items and cancel the contract immediately without refund. It is your responsibility to ensure
						that this type of mail is not posted to HotSnail.
					</p>

                  <p>
                    <b>Plans and Options</b>
                  </p>
                  <p>
                    It is your responsibility to choose the appropriate plan for your service and your pre-paid
                    credit will be charged accordingly.
					</p>
                  <p>Online plan changed take effect immediately.</p>
                  <p>
                    <b>Payment Terms</b>
                  </p>
                  <p>
                    You are required to pay for all services in advance by pre-paid credit, when the pre-paid credit
                    balance becomes less than zero further mail items will be locked until payment is received. If
						payment is not received within 14 days HotSnail will discontinue scanning items until the
						pre-paid credit balance is paid.
					</p>

                  <p>
                    <b>Pre-paid credit</b>
                  </p>
                  <p>
                    Your pre-paid credit is your running balance which should always be kept above zero. All charges
                    incurred will be deducted from the pre-paid credit’s balance. Your pre-paid credit can be
						reviewed and topped up on the HotSnail online management website. Pre-Paid credit is
						non-refundable and expires 12 months after purchase. The minimum amount you can top up your
						pre-paid credit is $20
					</p>
                  <p>Automatic Top up of pre-paid credit</p>
                  <p>
                    HotSnail utilises stripe for credit card payments. When your balance falls below the amount
						required to process your request(s) or maintain the service(s) provided to you, this will
						trigger an automatic top up of your account of the amount you have specified in your account
						settings in the HotSnail online management interface.
					</p>

                  <p>
                    <b>Forwarding to Us</b>
                  </p>
                  <p>
                    It is your responsibility to ensure that all mail is forwarded to HotSnail and we take
						absolutely no responsibility for mail which does not reach our PO Box. Once mail is received by
						HotSnail we will process according to options you have selected on our website, which may
						include opening, scanning, forwarding, storing or shredding.
					</p>

                  <p>
                    <b>Forwarding to you</b>
                  </p>
                  <p>
                    Mail which you request to have forwarded will be sent via Australia Post regular service. Mail
						HotSnail sends on your behalf is not sent by express or registered mail, unless specifically
						requested. Mail lost in transit is not the responsibility of HotSnail, however HotSnail
						endeavours to located lost items through Australia Post. Please refer to Australia Posts website
						at http://auspost.com.au for postage guidelines and restricted items.
					</p>

                  <p>
                    <b>Scanning</b>
                  </p>
                  <p>
                    HotSnail only opens your mail if you select the Scan everything plan or request a specific item
						scanned on the “selective scanning” plan as preconfigured on the HotSnail online mail management
						interface or during your account setup.
					</p>
                  <p>
                    If you choose “forward only” mail items will be stored or forwarded according to the options you
						have selected during account setup or on the HotSnail website and your pre-paid credit will be
						charged according to the applicable rates.
					</p>

                  <p>
                    If you select the “selective scanning” option on the HotSnail website, both sides of your
						envelope will be scanned and uploaded to your account and we will charge your pre-paid credit
						according to the applicable rates.
					</p>

                  <p>
                    If you select the “Scan Everything” option on the HotSnail website, HotSnail will open your mail
						and scan both sides of each page inside the mail item and your pre-paid credit will be charged
						according to the applicable rates.
					</p>

                  <p>
                    Non-Scannable items will be photographed by Digital Camera and the image will be uploaded to
						your HotSnail online account.
					</p>

                  <p>
                    <b>Storing</b>
                  </p>
                  <p>
                    All mail is stored securely for 30 days for free and after that time storage fees will apply.
						The Storage fees are listed under “Plans and Pricing” on the HotSnail website. Long term secure
						storage fees are deducted monthly from your pre-paid credit as per applicable rates.
					</p>

                  <p>
                    <b>Shredding</b>
                  </p>
                  <p>
                    HotSnail will shred mail which you request to be shredded either manually or automatically from
						the HotSnail online mail management interface or during your initial account setup.
					</p>

                  <p>
                    <b>Consent to open mail </b>
                  </p>
                  <p>
                    If you choose either “selective scanning” or “scan everything” plans, then you consent to
						HotSnail opening your mail.
					</p>

                  <p>
                    <b>Consent to shred mail</b>
                  </p>
                  <p>
                    If you request for your mail to be shredded either manually or automatically from either the
						HotSnail web interface or during your account setup, you consent to HotSnail shredding your
						mail. You consent to HotSnail shredding your mail if your account is automatically terminated
						due to insufficient fund for more than 90 days.
					</p>

                  <p>
                    <b>Indemnity</b>
                  </p>
                  <p>
                    You indemnify and hold HotSnail void from and against any and all claims, demands, actions,
						proceedings/or causes of action, and all damages, liabilities, costs or expenses relating to or
						arising from the provision of the services by HotSnail.
					</p>
                  <p>
                    <b>Identification</b>
                  </p>
                  <p>
                    HotSnail reserves the right require proof of account holder identity at any time.
					</p>
                  <p>
                    <b>Law and Jurisdiction</b>
                  </p>
                  <p>
                    The law of Queensland, Australia governs this contract. The parties submit to the non-exclusive
                    jurisdiction of the courts of Queensland governing this contract and any courts, which may hear
                    appeals from those courts in respect of any proceedings in connection with this contract.
					</p>

                  <p>
                    <b>International Mail </b>
                  </p>
                  <p>
                    HotSnail reserves the right to open and inspect the contents of any incoming or outgoing
						international mail.
					</p>


                  <p>
                    <b>Account Termination</b>
                  </p>
                  <p>
                    HotSnail reserves the right to cancel or suspend accounts at any time. Cancelled accounts will
						have their mail returned to sender.
					</p>
                  <p>
                    <b>Cancellation</b>
                  </p>
                  <p>
                    At the end of your contract all mail being stored will be returned to an address nominated by
						you at your expense. If you do not provide HotSnail with an address, HotSnail will send the mail
						back to the address listed on the contract and future items will be marked 'Return to Sender'.
						If you do not have credit in your pre-paid credit, the items will be sent via receiver pays COD.
					</p>
                  <p>
                    You may cancel this service at any time however you must do so in writing and give HotSnail 30
						days’ notice of your intention to do so. Prepaid credit is non-refundable.
					</p>
                  <p>
                    <b>Automatic Account Termination</b>
                  </p>
                  <p>
                    If you leave your account in negative balance for more than 90 days, and the credit card applied
						to your HotSnail account is unavailable or unable to top up the account we will automatically
						terminate your account and shred all your mail. All future mail we receive on your behalf will
						be returned to sender.
					</p>
                  <p>
                    <a href="/terms/Hotsnail Terms And Conditions.pdf">Download terms and conditions</a>
                  </p>
                </Box>
              </Col>
            </Row>
          </Container>
        </Section>
      </PageWrapper>
    </>
  );
};
export default TermsConditions;
